import React from 'react';
import ApplyNowMainComp from '../../../components/ApplyNow/ApplyNowMainComp';
import Layout from "../../../components/layout";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from 'styled-components';

const StyledWrapper = styled.div`
.toast-bar {
  color: white;
  background-color: #4dd2ff;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.toast-bar-progress {
  background: #00bfff;
}
`
export default function App( ) {
  const notify = () =>
    toast(
      "Thank you for registering for support from upReach. We will be in contact shortly.",
      {
        className: "toast-bar",
        progressClassName: "toast-bar-progress",
        autoClose: 5000,
      }
    );

  const notifyFn1 = () =>
    toast(
      "Thank you for saving your data.",
      {
        className: "toast-bar",
        progressClassName: "toast-bar-progress",
        autoClose: 5000,
      }
    );  
  return (
    <Layout>
    <StyledWrapper>
      <ToastContainer />
      <ApplyNowMainComp
        notify={notify} 
        notifySaveForLater={notifyFn1} 
      />
    </StyledWrapper>
    </Layout>
  )
}
