import React, { useState } from "react";
import styled from "styled-components";
import ApplyNowSecondForm from "./ApplyNowSecondPage";
import ApplyNowThirdForm from "./ApplyNowThirdPage";
import ApplyNowFourthForm from "./ApplyNowFourthPage";
import ApplyNowFifthForm from "./ApplyNowFifthpage";
import ApplyNowForms from "./Form";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from "react";
import loaderImage from "../../images/ajax.gif";
import { Helmet } from "react-helmet";

const StyledWrapper = styled.div`
  background-color: #F7F7F7;
  // width: 82%;
  padding: 5% 8% 0;
  // overflow: scroll;
  @media (max-width: 600px) {
    width: 95%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .progress {
    height: 1.3rem;
  }
  .progress-bar{
    background-color: #474cd8 !important
  }
  .progress-bar-text{
    color : #474cd8;
    font-size : 14px;
    font-weight : bold;
  }
  .progress-bar-pages .col{
    font-size:14px;
    @media (max-width: 600px) {
      font-size:12px;
    }
  }
  .form-control {
    height: 69px;
    width: 100% !important;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }
  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }
  .bg_load {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity:0.2;
    z-index:9999;
      display: flex;
    }
  .h_color {
    color: #4B0082 !important;
  }
`;

const ApplyNowMainComp = ({notify, notifySaveForLater}) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [mainData, setMainData] = useState({});
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [disbaleBtn, setDisableBtn] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [partialData, setpartialDataModel] = useState(false);
  const [allPartialData, setpartialData] = useState({});
  const [selectedUniversity, setSelectedUniversity] = useState(0);
  const [commonLayoutData, setCommonLayoutData] = useState({});
  const [navigationBack, setNavigation] = useState(false);
  const [associateToken, setAssociateToken] = useState();
  const [partialSaveUrl, setPartialSaveUrl] = useState(null);
  const [dummyToken, setDummyToken] = useState('');
  const [tokenError, setTokenError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = window.location.search.replace("?", '');
    setDummyToken(token);
    if (token) {
      fetchAssociateInfo(token);
    }
  }, [commonLayoutData?.token])

  const submitForm = data => {
    setLoading(true);
    window.scrollTo(0,0)
    setProgressValue(100)
    fetch(`${enkiUrl}/form/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

      .then(function (response) {
        if (response.status === 200) {
          notify();
          // handleClose();
          setError("");
          setDisableBtn(true);
          setTimeout(() => {
            window.location.href = "/apply-now/completed";
          }, 4000);
        } else {
          setLoading(false);
          return response.json().then(data => setError(data.message));
        }
      })
      .catch(err => {
        setDisableBtn(false);
      })
      // .finally(function () {
      //   setLoading(false);
      // });
  };

  const partialDataStore = data => {
    setpartialDataModel(true);
    setpartialData(data);
  };
  const handleClose1 = () => {
    setpartialDataModel(false);
  };

  const tokenErrorclose = () => {
    setTokenError(false);
    window.location.href = "/apply-now";
  };

  const savePartialData = () => {
    setLoading(true)
    let data = {};
    data = { ...allPartialData };
    if (dummyToken) {
      data.data.associate_token = dummyToken;
    }
    // var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(`${enkiUrl}/form/gef-partial-save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json()).then((data) => {
        // window.location.search=`?token=${data.associate_token}`
        if (data.status == 'SUCCESS') {
          window.history.replaceState(null, null, `?${data.associate_token}`);
          setTimeout(() => {
            window.location.reload()
          }, 4000);
          notifySaveForLater();
          setPartialSaveUrl(data.url);
          setDummyToken(data.associate_token);
          fetchAssociateInfo(data.associate_token);
          setError("");
        } else {
          setError(data.message);
        }
      })
      .catch(err => {
        setpartialDataModel(false);
      })
      // .finally(function () {
      //   setLoading(false);
      // });
  }

  async function fetchAssociateInfo(token) {
    const response = await fetch(
      `${enkiUrl}/form/associate/get-info-by-token/` + token
    );
    const responseJson = await response.json();
    if (responseJson.status == 'SUCCESS') {
      handleClose1();
      setError("");
      let partialData = Object.assign({}, responseJson.associate, responseJson.associate.partial_data)
      delete partialData.partial_data;
      partialData.token = token;
      setStep(partialData.page_id);
      if(partialData.not_at_university){
        setNavigation(true);
      }
      setProgressValue(partialData.progress_percentage);
      setCommonLayoutData(partialData);
    }
    else {
      return setTokenError(responseJson.message);
    }
  }

  return (
    <StyledWrapper className='apply-now'>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {
        (loading) &&
        <>
          <div raw-ajax-busy-indicator="" className="bg_load">
            <img src={loaderImage} className="m-auto" />
          </div>
          <div className="wrapper">
            <div className="inner">
            </div>
          </div>
        </>
      }
      <h1 className="mb-2 mb-md-4 h_color">Apply to upReach</h1>
      <p className="text-center progress-bar-text mb-0">{progressValue}% COMPLETE</p>
      <ProgressBar now={progressValue} />
      <Container fluid="md">
        <Row className="mb-4 progress-bar-pages">
          <Col className={navigationBack ? "text-center" : "text-right"}>Personal Details</Col>
          <Col className={navigationBack ? "text-center mr-5" : "text-center"}>Career Interests</Col>
          {
            !navigationBack &&
            <>
            <Col className="text-center">Eligibility</Col>
            <Col>Equality, Diversity and Inclusion</Col>
            </>
          }
        </Row>
      </Container>
      <Modal show={partialData}>
        <Modal.Header>
          <Modal.Title>Save For Later</Modal.Title>
        </Modal.Header>
        <Modal.Body>Need a break? We will email you a unique link to complete your assessment at a later time.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={() => savePartialData()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={tokenError} style={{ zIndex: 1500 }}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{tokenError}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={tokenErrorclose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {step === 1 && (
        <ApplyNowForms
          setStep={setStep}
          setErr={setError}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setNavigation={setNavigation}
          // handleClose={handleClose}
          selectedUniversity={selectedUniversity}
          setMainData={setMainData}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          mainData={mainData}
        />
      )
      }
      {step === 2 && (
        <ApplyNowSecondForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setSelectedUniversity={setSelectedUniversity}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          // handleClose={handleClose}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 3 && (
        <ApplyNowThirdForm
          setStep={setStep}
          navigation={navigationBack}
          setNavigation={setNavigation}
          partialDataStore={partialDataStore}
          setCommonLayoutData={setCommonLayoutData}
          setProgressValue={setProgressValue}
          submitForm={submitForm}
          err={error}
          commonLayoutData={commonLayoutData}
          // handleClose={handleClose}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 4 && (
        <ApplyNowFourthForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          // handleClose={handleClose}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 5 && (
        <ApplyNowFifthForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          submitForm={submitForm}
          setLoading={setLoading}
          loading={loading}
          err={error}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          // handleClose={handleClose}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
    </StyledWrapper>
  );
};

export default ApplyNowMainComp;
