import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Radio from '@material-ui/core/Radio';
import { Accordion } from 'react-bootstrap';

const StyledWrapper = styled.div`
.submit-form {
    height: 50px;
    width: 180px;
    background-color: #464fd0;
    border:none;
    @media (max-width: 480px) {
        width: 100%;
    }
  }
.cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
    @media (max-width: 480px) {
        width: 100%;
    }
}
.cancel-btn:focus{
    border:none;
    box-shadow:none;
  }
.career-heading{
    padding-left:8%;
    padding-right:8%;
    text-align:center;
    font-weight:800;
}
.career-title{
    font-weight:bold;
    font-size:18px;
}
.checkbox-link{
    display:inline-block;
    color: black;
    text-decoration: underline;
}
.form-check-label{
    font-size:16px;
    font-weight:normal;
}
.fail-text{
    padding-top: 10px;
    padding-right: 80px;
    font-weight:bolder;
    color:#E43D5A;
  }
.required-text{
    color:#E43D5A;
}
.accordion-header button{
    border: none;
    background: #F7F7F7;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    line-height: 20px;
}
.accordion-header button[aria-expanded="false"]::after {
    display: inline-block;
    float: right;
    margin-top: 5px;
    content: "";
    border-bottom: 0;
    border-right: 0.4em solid transparent;
    border-top: 0.4em solid;
    border-left: 0.4em solid transparent;
    color: #474cd8;
  }
  .accordion-header button[aria-expanded="true"]::after {
    display: inline-block;
    float: right;
    margin-top: 5px;
    content: "";
    border-top: 0;
    border-right: 0.4em solid transparent;
    border-bottom: 0.4em solid;
    border-left: 0.4em solid transparent;
    color: #474cd8;
  }
  .h_color {
    color: #4B0082 !important;
  }`

const ApplyNowThirdForm = ({ setStep, err, setProgressValue, submitForm, setNavigation, navigation, partialDataStore, setCommonLayoutData,
    commonLayoutData, }) => {
    const [selectedVal1, setSelectedVal1] = useState(null);
    const [selectedVal2, setSelectedVal2] = useState(null);
    const [careerStreamData, setCareerStreamData] = useState([]);
    const enkiUrl = process.env.GATSBY_ENKI_URL;
    const [error, setError] = useState("");
    const [dataPolicy, setDataPolicy] = useState(false);
    const [eligibility, setEligibility] = useState(false);
    const { register, getValues, handleSubmit, errors } = useForm({ mode: "onChange" });

    useEffect(() => {
        window.scrollTo(0,0);
        fetchCareerStreamData();
    }, [])

    useEffect(() => {
        setSelectedVal1(commonLayoutData?.first_choice || null)
        setSelectedVal2(commonLayoutData?.second_choice || null)
        setEligibility(commonLayoutData.form1_eligibility ? true : false)
        setDataPolicy(commonLayoutData.form1_data_policy ? true : false)
    }, [commonLayoutData]);

    const fetchCareerStreamData = async () => {
        const response = await fetch(
            `${enkiUrl}/career-streams/get-formone-active-career-stream`
        )
        const data = await response.json()
        setCareerStreamData(data.careerStreams);
    }

    const saveForLater = (pageId, progressPercent) => {
        let data = commonLayoutData;
        //save for later move to last updated page
        if(commonLayoutData.not_at_university == true){
            data.page_id = pageId;
            data.progress_percentage = progressPercent;
        }
        else{
            if(commonLayoutData.page_id > pageId){
                data.page_id = commonLayoutData.page_id;
                data.progress_percentage = commonLayoutData.progress_percentage;    
        }
        else{
                data.page_id = pageId;
                data.progress_percentage = progressPercent;
            }
        }
        partialDataStore({data});
    }

    const submitData = (data) => {
        if (selectedVal1 === null && selectedVal2 === null) {
            setError("Please select a first and second preference")
            return
        } else if (selectedVal1 === null) {
            setError("Please select a first preference")
            return
        } else if (selectedVal2 === null) {
            setError("Please select a second preference")
            return
        }

        const formData = {
            "first_choice": selectedVal1,
            "second_choice": selectedVal2,
        }

        setCommonLayoutData({ ...commonLayoutData, ...formData });
        if(commonLayoutData.not_at_university == true){
            submitForm(commonLayoutData);
          }else{
            setStep(4);
            setProgressValue("60");
          }
    }

    return (
        <StyledWrapper className='apply-now'>
            <Form onSubmit={handleSubmit(submitData)}>
                <Form.Label className="h_color">Career Interests</Form.Label>
                <p>Let us know which career sectors you are interested in joining in the future, so we can consider which programme
                    is most suitable for you. If you are unsure, please select Exploring. Please note that spaces on some of our
                    career programmes are limited.</p>
                <Form.Label>Preferred Future Career Sector (if known)</Form.Label>

                <div className="row border-bottom border-dark">
                    <div className="col-6"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6 text-center font-weight-bold">First Preference</div>
                            <div className="col-6 text-center font-weight-bold">Second Preference</div>
                        </div>
                    </div>
                </div>
                {
                    careerStreamData && careerStreamData.sort((a, b) => a.name.localeCompare(b.name)).map((data, index) => (
                        <div className="row border-bottom border-dark" key={index}>
                            <div className="col-6">
                                <Accordion flushdefaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{data?.name}</Accordion.Header>
                                        <Accordion.Body>{data?.description}</Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="col-6">
                                <div className="row d-flex justify-content-around">
                                    <Radio
                                        color="primary"
                                        checked={selectedVal1 === Number(data.id)}
                                        onChange={(e) => {
                                            setSelectedVal1(Number(e.target.value))
                                            commonLayoutData["first_choice"] = Number(e.target.value)
                                        }}
                                        value={data.id}
                                        name="radio-button-demo"
                                    />
                                    <Radio
                                        color="primary"
                                        checked={selectedVal2 === Number(data.id)}
                                        onChange={(e) => {
                                            setSelectedVal2(Number(e.target.value))
                                            commonLayoutData["second_choice"] = Number(e.target.value)
                                        }}
                                        value={data.id}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }
                {error !== "" && <p className="fail-text mb-0">{error}</p>}

                {commonLayoutData.not_at_university &&
                    <>
                    <Form.Group className="mt-4">
                        <Form.Check
                            ref={register({ required: true })}
                            name="form1_eligibility"
                            style={{ display: "inline-block" }}
                            id="eligibility"
                            htmlFor="eligibility"
                            onChange={() => {
                                setEligibility(!eligibility);
                                setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                            }}
                            checked={eligibility}
                            label={
                            <>
                                By ticking this box, I confirm that to the best of my knowledge
                                I meet upReach’s eligibility criteria
                                <a
                                href="/students/#row-who-we-support"
                                target="_blank"
                                className="checkbox-link ml-2"
                                >
                                (Who we support)
                                </a>
                            </>
                            }
                        />
                        {errors.form1_eligibility && (
                            <p className="mb-0 text-danger font-weight-bold">Required field</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Check
                            ref={register({ required: true })}
                            name="form1_data_policy"
                            id="form1_data_policy"
                            htmlFor="form1_data_policy"
                            label="By ticking this box, I agree to the "
                            feedback="You must agree before submitting."
                            onChange={() => {
                                setDataPolicy(!dataPolicy)
                                setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                            }}
                            checked={dataPolicy}
                            style={{ display: "inline-block" }}
                        />
                        <a
                            href="/upreach-associate-user-agreement"
                            target="_blank"
                            className="checkbox-link ml-2">
                            upReach's Associate User Agreement and Data Protection Policy.
                        </a>
                    </Form.Group>
                    </>
                }

                {errors.form1_data_policy && (
                    <p className="mb-0 text-danger font-weight-bold">Required</p>
                )}

                {err !== "" && <p className="fail-text mb-0">{err}</p>}

                <div className="row text-center mt-4">
                    <div className="col-md-2 my-auto text-md-left">
                        <Button className="submit-form mr-lg-5 mr-md-4" type="button" onClick={() => {
                            if (navigation) {
                                setStep(1); setProgressValue(0);
                            } else {
                                setStep(2); setProgressValue(20)
                            }
                            setNavigation(false)
                        }}>
                            BACK
                        </Button>
                    </div>
                    <div className="col-md-10 text-md-right">
                        <button onClick={(e) => {
                            e.preventDefault();
                            commonLayoutData.not_at_university == true ? saveForLater(3,66) : saveForLater(3,40);
                        }} className="mr-lg-5 mr-md-4 cancel-btn btn">
                            SAVE FOR LATER
                        </button>
                        <Button className="submit-form mt-3 p-2" type="submit">
                            {commonLayoutData.not_at_university == true ? "SUBMIT" : "NEXT" }
                        </Button>
                    </div>
                </div>
            </Form>
        </StyledWrapper>
    )
}

export default ApplyNowThirdForm;